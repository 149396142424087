#HeaderEdit {
  height: 400px;
  margin: 50px;
  box-sizing: border-box;
}

#HeaderImg {
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 10px lightgray;
}

#headerTop {
  text-align: center;
  position: relative;
  margin: 20px 50px;
  height: 50px;
  font-size: 40px;
}

#viewButtonsContainer {
  width: 100px;
  height: 50px;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
}

#viewButtonsContainer:hover {
  cursor: pointer;
}

#selectedBox {
  position: absolute;
  width: 50px;
  height: 100%;
  transition: all 0.2s;
  background-color: rgba(0, 0, 0, 0.1);
  left: 0;
}

#cardsButton,
#rowsButton {
  display: flex;
  flex-wrap: wrap;
  width: 50px;
  justify-content: center;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
}

#cardsButton div {
  display: flex;
  flex-wrap: wrap;
  width: 45px;
  justify-content: center;
}

#cardsButton .box {
  width: 10px;
  height: 10px;
  margin: 1px;
  background-color: gray;
  box-sizing: border-box;
}

#rowsButton div {
  display: flex;
  flex-wrap: wrap;
  width: 45px;
  justify-content: center;
}

#rowsButton .box {
  width: 80%;
  height: 5px;
  margin: 1px;
  background-color: gray;
  box-sizing: border-box;
}

#galleryImageEditContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 30px;
  justify-content: center;
}

#galleryImageRow {
  display: flex;
  flex-direction: column;
}

.editImageCard {
  width: 300px;
  /* height: 400px; */
  margin: 30px;
  background-color: #f9fbfd;
  border-radius: 5px;
  padding-bottom: 10px;
  box-shadow: 0px 0px 5px lightgray;
}

.editImage {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 200px;
  border-radius: 5px 5px 0 0;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.saveButton {
  margin: 10px;
  padding: 10px;
  border: 1px solid #3f51b5;
  color: #3f51b5;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.saveButton:hover {
  cursor: pointer;
}

.makeHeaderButton {
  border: 1px solid #3f51b5;
  color: #3f51b5;
  font-weight: bold;
  margin: 10px;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
  width: 40%;
}

.makeHeaderButton:hover {
  cursor: pointer;
}

.deleteImageButton {
  border: 1px solid #f44336;
  color: #f44336;
  font-weight: bold;
  margin: 10px;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  width: 40%;
}

.deleteImageButton:hover {
  cursor: pointer;
}

.creditInputContainer {
  padding: 10px;
}

.creditInputContainer p {
  font-size: 12px;
}

.creditInputContainer input {
  border: none;
  background-color: transparent;
  color: black;
  border-radius: 0;
  border-bottom: 1px solid #150680;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 0;
}

.creditInputContainer input::placeholder {
  color: black;
}

.addButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 9px;
  text-align: center;
  font-weight: 100;
  width: 75px;
  height: 75px;
  box-sizing: border-box;
  font-size: 50px;
  border-radius: 100px;
  background-color: white;
  box-shadow: 1px 1px 10px lightgray;
  /* border: 1px solid #1a237e; */
  color: #1a237e;
}

.addButton:hover {
  cursor: pointer;
}

.saveContainer {
  display: flex;
  align-items: center;
}

#galleryImageRow .editImageCard {
  width: 96%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 200px 2fr 1fr 1fr 1fr 1fr;
  height: 75px;
  justify-items: center;
  margin: 15px 30px;
}

#galleryImageRow .editImage {
  height: 75px;
  border-radius: 5px 0 0 5px;
}

#galleryImageRow .deleteImageButton,
#galleryImageRow .saveButton,
#galleryImageRow .makeHeaderButton {
  width: 100%;
}

.gridItem {
  width: 95%;
  display: flex;
  align-items: center;
  text-align: center;
}

#galleryImageRow .creditInputContainer p {
  font-size: 18px;
  margin-right: 10px;
}

@media (max-width: 1024px) {
  #wordsContainer {
    font-size: 35px;
  }

  #headerTop {
    font-size: 15px;
    margin: 20px 10px 10px 10px;
    height: auto;
  }

  #galleryImageEditContainer {
    margin: 0;
  }

  #viewButtonsContainer {
    display: none;
  }

  #HeaderEdit {
    margin: 10px 20px;
    height: 200px;
  }

  .buttonsContainer {
    text-align: center;
  }

  .makeHeaderButton,
  .deleteImageButton,
  .saveButton {
    font-size: 12px;
  }
}
