.ReactModal__Content {
  width: 500px;
}

#modal {
  text-align: center;
}

#modal h2 {
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
}

input[type="file"] {
  display: none;
}

#fileUploadButton {
  padding: 10px 20px;
  border: 1px solid #3f51b5;
  color: #3f51b5;
  font-weight: bold;
  border-radius: 5px;
}

#fileUploadButton:hover {
  cursor: pointer;
}

#modal input[type="text"] {
  border: none;
  background-color: white;
  color: black;
  border-radius: 0;
  border-bottom: 1px solid #3f51b5;
  margin-left: 10px;
  margin-top: 30px;
  font-size: 15px;
  padding-bottom: 5px;
}

#modal input::placeholder {
  color: black;
}

#modalSubmit {
  padding: 10px 20px;
  text-align: center;
  border: 1px solid #3f51b5;
  color: #3f51b5;
  font-weight: bold;
  width: 100%;
  box-sizing: border-box;
}

#modalSubmit:hover {
  cursor: pointer;
}

#submitContainer {
  display: flex;
  margin-top: 40px;
  align-items: center;
}

@media (max-width: 1024px) {
  .ReactModal__Content {
    width: 300px;
  }
}
