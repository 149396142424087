#logo {
  /* background-image: url("./images/pxpLogo.png");
    height: 200px;
    width: 300px;
    background-position: center;
    background-size: cover; */
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 2;
  opacity: 0.7;
  /* top: -45px;
    left:-70px; */
  opacity: 0;
  -webkit-animation: fadeinLogo 2s forwards; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeinLogo 2s forwards; /* Firefox < 16 */
  -ms-animation: fadeinLogo 2s forwards; /* Internet Explorer */
  -o-animation: fadeinLogo 2s forwards; /* Opera < 12.1 */
  animation: fadeinLogo 2s forwards;
  animation-delay: 1s;
}

#logo img {
  height: 40px;
  padding: 10px;
}

#navContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

#mobileNav {
  display: none;
}

#mobileNavLinks {
  display: none;
}

.navItem {
  display: inline-block;
  padding-left: 20px;
}
.navItem a {
  text-decoration: none;
  color: black;
}

.navItem .inputBorder {
  margin: 0;
  width: 10px;
  height: 1px;
  transition: all 0.3s;
}

.nav a:hover ~ .inputBorder {
  width: 100%;
}

.navItem a:hover {
  cursor: pointer;
}

.navItem .inputBorder {
  background-color: black;
}

@keyframes fadeinLogo {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeinLogo {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeinLogo {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeinLogo {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeinLogo {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .navItem a {
    font-size: 30px;
  }

  #navContainer {
    display: none;
  }

  #mobileNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #logo {
    padding: 0px;
  }

  #hamburger i {
    font-size: 50px;
    margin-right: 50px;
  }

  #mobileNav img {
    height: 50px;
    margin-left: 30px;
  }

  .mobileNavLink {
    display: block;
    font-size: 25px;
    padding: 20px 0;
    text-align: center;
    border-top: 1px solid black;
    text-decoration: none;
    color: black;
  }

  #mobileNavLinks {
    border-bottom: 1px solid black;
  }
}
