#a404Container {
  background-image: url(../../../images/pexels-alleksana-4271933.jpg);
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
}

#a404Container p {
  font-size: 50px;
  margin-bottom: 100px;
}
