/***************************************/
/**** Styles for the home jumbotron ****/
/***************************************/

#homeJumbotron {
  background-image: url("../../images/jumboTile.jpg");
  min-width: 100%;
  min-height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

#jumboOverlay {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 1);

  -webkit-animation: fadeinBackground 2s forwards; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeinBackground 2s forwards; /* Firefox < 16 */
  -ms-animation: fadeinBackground 2s forwards; /* Internet Explorer */
  -o-animation: fadeinBackground 2s forwards; /* Opera < 12.1 */
  animation: fadeinBackground 2s forwards;
  /* background-color: rgba(0,0,0,0.4); */
  animation-delay: 1s;
}

#jumboContent h1 {
  color: white;
  font-size: 100px;
  font-weight: 100;
  margin-top: -100px;
  text-shadow: 1px 1px black;
  -webkit-animation: fadeinLogo 2s forwards; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeinLogo 2s forwards; /* Firefox < 16 */
  -ms-animation: fadeinLogo 2s forwards; /* Internet Explorer */
  -o-animation: fadeinLogo 2s forwards; /* Opera < 12.1 */
  animation: fadeinLogo 2s forwards;
}

@keyframes fadeinBackground {
  from {
    background-color: rgba(0, 0, 27, 1);
  }
  to {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeinBackground {
  from {
    background-color: rgba(0, 0, 27, 1);
  }
  to {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeinBackground {
  from {
    background-color: rgba(0, 0, 27, 1);
  }
  to {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

/* Internet Explorer */
@-ms-keyframes fadeinBackground {
  from {
    background-color: rgba(0, 0, 27, 1);
  }
  to {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeinBackground {
  from {
    background-color: rgba(0, 0, 27, 1);
  }
  to {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

/***************************************/
/***** Styles for the home content *****/
/***************************************/

@keyframes fadeinBackgroundBelow {
  from {
    background-color: rgba(0, 0, 27, 1);
  }
  to {
    background-color: #f7f7f7;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeinBackgroundBelow {
  from {
    background-color: rgba(0, 0, 27, 1);
  }
  to {
    background-color: #f7f7f7;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeinBackgroundBelow {
  from {
    background-color: rgba(0, 0, 27, 1);
  }
  to {
    background-color: #f7f7f7;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeinBackgroundBelow {
  from {
    background-color: rgba(0, 0, 27, 1);
  }
  to {
    background-color: #f7f7f7;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeinBackgroundBelow {
  from {
    background-color: rgba(0, 0, 27, 1);
  }
  to {
    background-color: #f7f7f7;
  }
}

#homeContentContainer {
  /* #25274D */
  background-color: rgba(0, 0, 0, 1);
  padding: 0px 20px 50px 20px;
  position: relative;
  -webkit-animation: fadeinBackgroundBelow 2s forwards; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeinBackgroundBelow 2s forwards; /* Firefox < 16 */
  -ms-animation: fadeinBackgroundBelow 2s forwards; /* Internet Explorer */
  -o-animation: fadeinBackgroundBelow 2s forwards; /* Opera < 12.1 */
  animation: fadeinBackgroundBelow 2s forwards;
  animation-delay: 1s;
  min-height: 1900px;
}

#content {
  opacity: 0;
  -webkit-animation: fadeinLogo 2s forwards; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeinLogo 2s forwards; /* Firefox < 16 */
  -ms-animation: fadeinLogo 2s forwards; /* Internet Explorer */
  -o-animation: fadeinLogo 2s forwards; /* Opera < 12.1 */
  animation: fadeinLogo 2s forwards;
  animation-delay: 1s;
  min-width: 75%;
  position: absolute;
  top: -150px;
  left: 50%;
  transform: translateX(-50%);
}

#firstSection {
  background-color: white;

  border-radius: 7px;
  text-align: center;
}

#firstSection h1 {
  font-size: 45px;
  font-weight: 100;
  padding: 60px 0 40px 0;
}

#firstDivider {
  width: 50%;
  height: 1px;
  background-color: gray;
  margin: 0 auto 80px auto;
}

#firstSectionPhoto {
  margin-top: 80px;
  background-image: url(../../images/yellow-utility-knives-1409215.jpg);
  width: 100%;
  height: 400px;
  background-position: center;
  background-size: cover;
  border-radius: 0 0 7px 7px;
}

#iconBanner {
  display: flex;
  justify-content: space-evenly;
}

.iconItem {
  width: 25%;
}

.iconItem:first-child i {
  color: #82b1ff;
}

.iconItem:nth-child(2) i {
  color: #ffd180;
}

.iconItem:last-child i {
  color: #78909c;
}

#iconBanner i {
  font-size: 100px;
}

#iconBanner h2 {
  font-weight: 100;
  margin-top: 20px;
}

#secondSection {
  display: flex;
  justify-content: space-between;
}

.secondSectionCard {
  width: 48%;
  height: 500px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  font-size: 60px;
  font-weight: 100;
  color: white;
  text-decoration: none;
}

.card {
  box-shadow: 0px 4px 5px lightgray;
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.underline {
  width: 0;
  height: 1px;
  background-color: white;
  transition: all 0.5s;
}

.card:hover .underline {
  width: 100%;
}

#backsplashImg {
  background-image: url(../../images/homeBacksplash.jpg);
  background-position: center;
  background-size: cover;
}

#showersImg {
  /* background-image: url(images/shower.jpg);
     */
  background-image: url(../../images/shower18.PNG);
  background-position: center;
  background-size: cover;
}

#fireplacesImg {
  background-image: url(../../images/fireplace.jpg);
  background-position: center;
  background-size: cover;
  height: 500px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  font-size: 60px;
  font-weight: 100;
  color: white;
  text-decoration: none;
}

.homeHeader {
  text-shadow: 1px 1px 3px black;
  font-weight: 300;
}

#fireplacesText {
  text-shadow: 2px 2px 3px black;
}

@media (max-width: 1024px) {
  #jumboContent h1 {
    font-size: 200%;
    font-weight: 300;
    margin-top: -50px;
  }

  #homeContentContainer {
    min-height: 750px;
  }

  #content {
    top: -75px;
  }

  #middleSectionBackground {
    height: 750px;
  }

  #firstSection h1 {
    font-size: 22px;
    padding: 20px 20px 10px 20px;
  }

  #firstSection #firstDivider {
    margin: 0 auto 30px auto;
  }

  #firstSection h2 {
    font-size: 13px;
  }

  #firstSection i {
    font-size: 40px;
  }

  #firstSectionPhoto {
    margin-top: 40px;
    height: 200px;
  }

  .secondSectionCard {
    height: 250px;
    margin-top: 25px;
  }

  .homeHeader {
    font-size: 20px;
  }

  #homeJumbotron,
  #backsplashJumbo {
    background-attachment: scroll;
  }

  #homeContentContainer {
    padding-top: 250px;
  }

  .card {
    font-size: 40px;
  }

  #fireplacesText {
    font-size: 20px;
  }

  #fireplacesImg {
    height: 250px;
    margin-top: 25px;
  }
}
