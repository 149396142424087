/* via https://projects.lukehaas.me/css-loaders/ */
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(21, 6, 128, 0.2);
  border-right: 1.1em solid rgba(21, 6, 128, 0.2);
  border-bottom: 1.1em solid rgba(21, 6, 128, 0.2);
  border-left: 1.1em solid #150680;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.small,
.small:after {
  width: 3em;
  height: 3em;
}

.small {
  border-top: 0.2em solid rgba(21, 6, 128, 0.2);
  border-right: 0.2em solid rgba(21, 6, 128, 0.2);
  border-bottom: 0.2em solid rgba(21, 6, 128, 0.2);
  border-left: 0.2em solid #150680;
  margin: 5px;
  margin-left: 10px;
  box-sizing: border-box;
}

.white {
  border-left: 0.2em solid white;
}
