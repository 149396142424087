#deleteButtonContainer {
  display: flex;
  justify-content: space-around;
  padding: 0 30px;
}

.cancelButton {
  background-color: transparent;
  color: #3f51b5;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #3f51b5;
}

.deleteModalButton {
  background-color: transparent;
  padding: 10px 20px;
  color: #f44336;
  font-weight: bold;
  border-radius: 5px;
  border: 1px solid #f44336;
}

.cancelButton:hover,
.deleteModalButton:hover {
  cursor: pointer;
}
