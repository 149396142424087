#adminContainer {
  display: flex;
  height: 100vh;
}

#leftImg {
  width: 50%;
  height: 100%;
  background-image: url(../../images/pexels-anamul-rezwan-1145434.jpg);
  background-position: center;
  background-size: cover;
}

#formContainer {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#formContainer h1 {
  color: #150680;
  font-size: 40px;
  margin-bottom: 50px;
}

#formContainer input {
  margin: 0;
  padding: 0;
  border-radius: 0;
  font-size: 20px;
  width: 100%;
  display: block;
  background-color: white;
  border: none;
  border-bottom: 1px solid #150680;
  padding-bottom: 5px;
  margin-top: 20px;
  color: black;
  font-weight: 100;
  width: 110%;
}

::placeholder {
  color: gray;
}

#formContainer #submitButton {
  background-color: #150680;
  color: white;
  border: none;
  margin-top: 40px;
  font-weight: 300;
  padding: 5px 0;
}

#formContainer #submitButton:hover {
  cursor: pointer;
}

#adminContainer input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-text-fill-color: black !important;
}

@media (max-width: 1024px) {
  #adminContainer {
    flex-direction: column;
  }

  #leftImg {
    width: 100%;
    height: 50%;
  }

  #formContainer {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }

  #formContainer input {
    width: 100%;
  }

  #formContainer h1 {
    font-size: 25px;
    margin-bottom: 25px;
  }
}
