#aboutContainer {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px 50px;
}

h1 {
  margin-bottom: 10px;
}

#aboutContainer p {
  margin-bottom: 10px;
}

#quickLinks {
  /* display: flex;
  flex-wrap: wrap; */
  padding: 0px 20px;
}

#quickLinks a {
  display: block;
  margin-bottom: 3px;
}

#head {
  text-align: center;
}

.contentSection {
  margin: 50px 0;
}

.contentSection h3 {
  font-size: 22px;
  margin-bottom: 10px;
}

.helpfulGif {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 700px;
}

@media (max-width: 1024px) {
  #aboutContainer {
    padding: 20px;
  }
}
