#dashboard-container {
  display: flex;
  position: relative;
}

#sidebar {
  width: 110px;
  height: 100vh;
  position: fixed;
  /* background-color: #150680; */
  background-color: #1a237e;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* box-shadow: 1px 1px 10px lightgray; */
}

.is-active ~ .arrow-left {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;

  border-right: 10px solid #f9fbfd;
}

.sidebarLink {
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: 20px 0px 20px 10px;
  /* border-bottom: 1px solid #7866f8; */
  border-bottom: 1px solid #000051;
  font-weight: bold;
}

.sidebarLink:last-child {
  border-bottom: none;
}

.sidebarLink a {
  color: white;
}

#dashboard-child {
  padding: 75px 0 50px 110px;
  box-sizing: border-box;
  width: 100%;
  background-color: #f9fbfd;
  min-height: 100vh;
  position: relative;
}

@media (max-width: 1024px) {
  #sidebar {
    width: 70px;
    font-size: 10px;
  }

  #dashboard-child {
    padding: 75px 0 50px 70px;
  }
}
