/***************************************/
/************ Footer Styles ************/
/***************************************/

.footer {
  background-color: #181818;
  color: gray;
  text-align: center;
}

.footerContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.form {
  display: inline-block;
}

.form h1 {
  margin-top: 30px;
  font-weight: 100;
  color: white;
}

.form h1,
.form h3 {
  text-align: left;
}

.form h3 {
  font-size: 15px;
  margin-bottom: 10px;
}

.divider {
  width: 100px;
  height: 2px;
  background-color: white;
  margin: 10px 0;
}

.inputContainer {
  display: inline-block;
}

.inputRow {
  text-align: left;
  position: relative;
  margin-bottom: 10px;
}

.lastRow {
  /* margin-bottom: 20px; */
}

.inputBorder {
  height: 1px;
  background-color: white;
  margin-bottom: 10px;
  width: 10%;
  transition: all 0.3s;
}

.inputRow .inputBorder {
  height: 1px;
  background-color: white;
  width: 0%;
  margin-bottom: 0;
  transition: all 0.3s;
  position: absolute;
  bottom: 0;
}

.inputRow .inputBorderError,
.inputRow .inputBorderErrorTextArea {
  height: 1px;
  background-color: red;
  width: 50%;
  margin-bottom: 0;
  transition: all 0.3s;
  position: absolute;
  bottom: 0;
}

.inputRow .inputBorderErrorTextArea {
  width: 100%;
}

.inputRow input {
  font-size: 15px;
  padding: 7px 5px;
  width: 200px;
  color: white;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid gray;
  /* border-radius: 7px; */
}

::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

input:focus,
textarea:focus {
  outline: none;
}

input:focus ~ .inputBorder {
  width: 50%;
}

textarea:focus ~ .inputBorder {
  width: 100%;
}

textarea {
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  min-height: 30px;
  padding: 5px 2px;
  font-size: 15px;
  resize: vertical;
  color: white;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid gray;
  margin-bottom: -4px;
}

#SubmitButton,
#SubmitButtonLoad {
  border: 1px solid gray;
  font-size: 15px;
  padding: 5px 0;
  margin: 10px 0;
  transition: all 0.2s;
  width: 100%;
}

#SubmitButton:hover {
  cursor: pointer;
  opacity: 0.8;
}

#Message,
#ErrorMessage,
#SuccessMessage {
  text-align: left;
  margin-top: 5px;
}

#ErrorMessage {
  color: red;
}

#SuccessMessage {
  color: #50c878;
}

.footerNav {
  text-align: left;
}

.footerNav .divider {
  width: 40%;
}

.footerNav a {
  color: white;
  text-decoration: none;
}

.footerNav .inputBorder {
  width: 20%;
}

.footerNavItem div:first-child:hover ~ .inputBorder {
  width: 100%;
}

.copyright {
  padding: 10px 0 10px 0;
  margin-top: 10px;
  border-top: 1px solid #2d2d2d;
  color: white;
}

a {
  text-decoration: none;
}

#submitFooterContainer {
  display: flex;
}

#SubmitButtonLoad {
  width: 90%;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-internal-autofill-selected,
input:-internal-autofill-previewed,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #181818 !important;
  color: white !important;
  -webkit-background-clip: text;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}

@media (max-width: 1024px) {
  .inputRow input {
    padding: 5px 5px;
  }

  input,
  textarea {
    width: 100%;
  }

  .inputRow {
    margin-bottom: 0;
  }

  .inputContainer {
    position: relative;
  }

  .inputRow input {
    width: 100%;
    box-sizing: border-box;
  }

  .inputBorder {
    height: 2px;
  }

  input:focus ~ .inputBorder {
    width: 100%;
  }

  ::placeholder {
    padding-left: 10px;
  }

  #ErrorMessage,
  #SuccessMessage,
  #Message {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .inputContainer,
  textarea {
    width: 100%;
    margin-bottom: 30px;
  }

  .footerContainer {
    display: inline-block;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }

  textarea {
    /* min-height: 50px; */
    font-size: 15px;
  }

  #ContentBorder {
    bottom: 34px;
  }

  .form h1 {
    font-size: 30px;
    margin-bottom: 40px;
  }

  .form h3 {
    margin-top: 40px;
    font-size: 22px;
    font-weight: 100;
    margin-bottom: 40px;
  }

  #SubmitButton {
    font-size: 18px;
  }

  .footerNav {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 40px;
  }

  .footerNavItem {
    display: inline-block;
    margin: 0 5px;
  }
  .copyright {
    font-size: 13px;
  }

  .footerDivider {
    display: none;
  }
}
